@import "~antd/dist/antd.less";

* {
  box-sizing: border-box;
  // color: white;
  font-family: "Roboto", sans-serif;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow: auto;
  padding: 0;
}

#root {
  display: flex;
  min-height: 100%;
  width: 100%;
  flex: 1 1 auto;
}

.ant-row {
  margin-bottom: 0.5rem;
}

.ant-card-meta-title,
.ant-card-head-title {
  text-transform: uppercase;
  font-size: 13px;
}

.ant-tabs-nav {
  text-transform: uppercase;
}

//Used for change bg of the tabs line
.tabs-custom .ant-card-head {
  background: #0d0f11;
  padding: 0;
}

.tabs-custom .ant-tabs-nav .ant-tabs-tab {
  margin-right: 0;
  font-size: 13px;
}

.tabs-custom .ant-tabs-tab-active {
  background: #161b20;
}

.tabs-custom .ant-tabs-tab:not(.ant-tabs-tab-active) {
  background: #111519;
}

.tabs-custom .ant-tabs-ink-bar {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

Button .anticon {
  color: white;
}

@primary-color: #eb3223;@success-color: #72c040;@heading-color: #757575;@icon-color: @heading-color;@disabled-color: #757575;@text-color-secondary: #8c8c8c;@component-background: var(--component-background);@font-family: 'Roboto', sans-serif;@typography-title-font-weight: 400;@heading-1-size: 32px;@font-size-base: 13px;@line-height-base: 1.25;@text-color: #757575;@border-color-base: #0c0f12;@border-color-split: @disabled-color;@border-radius-base: 0;@border-radius-sm: 0;@layout-body-background: #0c0f12;@layout-header-background: #161b20;@layout-header-height: 80px;@menu-bg: #111519;@menu-item-height: 50px;@menu-item-active-bg: #eb3223;@list-item-padding: 0 0;@tag-default-color: #0c0f12;@tag-font-size: 12px;@avatar-bg: #2b2b2b;@btn-default-border: #eb3223;@btn-default-color: @btn-default-border;@btn-disable-color: #262626;@btn-disable-bg: @disabled-color;@btn-disable-border: @disabled-color;@card-head-color: #eb3223;@slider-rail-background-color: #4a4a4a;@slider-track-background-color: #72c040;@slider-dot-border-color: #72c040;@slider-rail-background-color-hover: #909090;@slider-track-background-color-hover: #56922f;@slider-dot-border-color-active: #72c040;@slider-handle-color-focus: #72c040;@tooltip-bg: #56922f;@input-bg: #161b20;@input-color: #ffffff;@input-placeholder-color: #505050;@input-font-weight: 300;@input-disabled-bg: var(--component-background);@table-selected-row-hover-bg: transparent;@table-selected-row-bg: transparent;@table-row-hover-bg: transparent;@process-title-color: #eb3223;@skeleton-color: #262626;
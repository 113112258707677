.Logo {
  height: 44px;
  transform: scale(5);
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.HeaderMobile:global(.ant-layout-header) {
  padding-right: 30px;
}

.Footer:global(.ant-layout-footer) {
  display: block;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 100%;
  padding: 24px 30px;
}

.LandingVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  filter: brightness(0.7);
}

.Container {
  z-index: 1;
  background: rgba(0, 0, 0, 0.1) !important;
}

.TransparentContent {
  background: rgba(0, 0, 0, 0.1) !important;
}

.TransparentHeader {
  background: rgba(0, 0, 0, 0.5) !important;
}

.TransparentFooter {
  background: rgba(0, 0, 0, 0.1) !important;
}

.TagLine {
  text-align: center;
  font-size: 40px;
  font-weight: 300;
  margin: 60px;
}

.Highlight {
  color: var(--primary-color);
  font-weight: 500;
}

.Row {
  margin: 40px 20px 20px 20px;
  font-weight: 300;
  font-size: 15px;
}

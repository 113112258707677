.Link {
  text-transform: uppercase;
  color: var(--color-gray);
  font-weight: 300;
  margin-right: 20px;
}

.Copyright {
  text-transform: uppercase;
  color: var(--text-color-secondary);
  font-weight: 300;
  /* margin-right: 20px; */
}

.LeftContainer {
  width: 90%;
  display: flex;
}

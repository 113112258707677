.mainImage {
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.logoWrapper {
  position: relative;
  width: 90px;
  display: inline-table;
  margin-left: 10px;
}

.Item {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  height: 80px;
  justify-content: center;
}

.ItemWithType {
  flex-direction: row;
  align-items: center;
  font-weight: 13px;
  line-height: 1.25;
  text-transform: uppercase;
}

.EntityName {
  color: white;
}

.EntityType {
  margin-top: 5px;
}

.Avatar {
  margin-right: 12px;
  border: 2px solid #eb3223;
  box-sizing: content-box;
}

.Border {
  border-left: 2px solid #111519;
  padding: 0 20px;
  line-height: 1.25;
}

.Balance span {
  font-size: 13px;
  text-transform: uppercase;
}

.Balance p {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 0;
}

.PublicNav a {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  margin-left: 1.75rem;
}

.PublicNav {
  display: inline;
  flex-grow: 1;
  justify-content: center;
  margin-right: 20px;
}

.PublicNav :global(.active) {
  color: var(--primary-color);
}

.Navigation {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-right: 20px;
}

.Navigation a {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  margin-left: 1.75rem;
}

.Navigation :global(.active) {
  color: var(--primary-color);
}

.Hamburger:global(.anticon) {
  font-size: 30px;
  color: var(--primary-color);
}

.HamburgerItem {
  margin-bottom: 20px;
}
